<template>
    <div>
        <b-button-toolbar>
            <b-button-group>
                <b-button
                    :to="{
                        name:
                            $route.name == 'FestivalCard'
                                ? 'Festival'
                                : 'FestivalCard',
                    }"
                >
                    Назад
                </b-button>
                <b-button
                    v-if="$route.name != 'FestivalSpectacle'"
                    :to="{ name: 'FestivalSpectacle' }"
                >
                    Спектакли
                </b-button>
                <b-button
                    v-if="$route.name != 'FestivalPlace'"
                    :to="{ name: 'FestivalPlace' }"
                >
                    Места проведения
                </b-button>
            </b-button-group>
            <b-button-group class="mx-3">
                <b-button
                    variant="success"
                    @click="add"
                    v-if="!$route.meta.hideAdd"
                    >Добавить</b-button
                >
                <b-button variant="primary" @click="update">Обновить</b-button>
            </b-button-group>
        </b-button-toolbar>
        <router-view ref="view" />
    </div>
</template> 

<script>
export default {
    name: "FestivalCardRoot",
    methods: {
        update() {
            this.$refs.view.update();
        },
        add() {
            this.$refs.view.add();
        },
    },
};
</script>